import { useContext, useMemo } from "react";
import { formatTimezoneDate } from "@/lib/formatter";
import { TriggerType } from "app/modules/detection/detection.interfaces";
import SettingsStore from "app/modules/settings/settings.context";
import TextButton from "stories/base/buttons/text-button/text-button";
import Typograph from "stories/type/typograph/typograph";
import {
  CardContainer,
  CardBottomContainer,
  CardTopContainer,
  CardAutoAccept,
  CardActions,
  CardDateTime,
  CaptionWrapper
} from './trigger-card.style';
import OutlineButton from "stories/base/buttons/outlined-button/outline-button";
import FilledButton from "stories/base/buttons/filled-button/filled-button";

interface Props {
  onMoreInfoClick?: () => void;
  onApprove?: () => void;
  onReject?: () => void;
  onClick?: () => void;
  trigger: TriggerType;
  hasActions: boolean;
  selected?: boolean;
}

/**
 *
 * @param props
 */
const getButtons = (highlighted: boolean) => {
  const ApproveButton = OutlineButton;
  const RejectButton = highlighted ? OutlineButton : FilledButton;
  return [ApproveButton, RejectButton];
};

/**
 *
 * @param props
 * @returns
 */
const TriggerCard = (props: Props) => {
  const settingsContext = useContext(SettingsStore)
  const datetime = useMemo(() => {
    const timezone = settingsContext.state.timezone?.id || 'utc';
    return {
      date: formatTimezoneDate({
        date: props.trigger.tstamp,
        timezone,
        format: 'll',
      }),
      time: formatTimezoneDate({
        date: props.trigger.tstamp,
        timezone,
        format: 'H:mm:ss A',
      }),
    };
  }, [props.trigger.tstamp]);

  const [ApproveButton, RejectButton] = getButtons(props.selected || false);

  return (
    <CardContainer onClick={props.onClick} className={props.selected ? '_highlighted_trigger' : undefined}>
      <CardTopContainer $highlighted={!!props.selected}>
        {
          props.trigger.auto_accepted ? (
            <CardAutoAccept>AUTO</CardAutoAccept>
          ) : null
        }

        <CardDateTime>
          <Typograph type="caption">{datetime.date}</Typograph>
          <Typograph type="subtitle2">{datetime.time}</Typograph>
        </CardDateTime>

        <CardActions>
          {
            props.onApprove && props.hasActions ? (
              <ApproveButton
                onClick={props.onApprove}
                text="Approve"
              />
            ) : null
          }

          {
            props.onReject && props.hasActions ? (
              <RejectButton
                onClick={props.onReject}
                text="Reject"
              />
            ) : null
          }
        </CardActions>

      </CardTopContainer>
      <CardBottomContainer>
        <CaptionWrapper>
          <Typograph type="caption">
            <strong>Device:</strong> {props.trigger.device_sn}
          </Typograph>
          <Typograph type="caption">
            <strong>Source:</strong> {props.trigger.source}
          </Typograph>
          <Typograph type="caption">
            <strong>Confidence level:</strong> {props.trigger.confidence_level || 0}%
          </Typograph>
          <Typograph type="caption">
            <strong>Frequency:</strong> {props.trigger.frequency}
          </Typograph>
        </CaptionWrapper>

        {
          props.onMoreInfoClick ? (
            <TextButton
              styleType="primary"
              onClick={props.onMoreInfoClick}
              text="Trigger Data"
              style={{ padding: 0 }}
            />
          ) : null
        }
      </CardBottomContainer>
    </CardContainer>
  );
};

export default TriggerCard;
