import { ReactElement, useContext, useEffect, useState } from 'react';
import SettingsStore from 'app/modules/settings/settings.context';
import {
  changeMapOverlay,
  renderMeasureTool,
  setListeners,
} from '../../map.controller';
import {
  ControlsComp,
  ToggleControlsContainer,
} from './controls.style';
import { HideControls, ShowControls } from '../../map.svgs';
import InspectionStore from 'app/modules/inspection/inspection.context';
import { InspectionProviderValue } from 'app/modules/inspection/inspection.context.d';
import { SettingsContext } from 'app/modules/settings/settings.context.d';
import MeasureTool from 'lib/measuretool-googlemaps-v3/src';
import ZoomButton from '../zoom-button/zoom-button';
import FollowButtons from '../follow-buttons/follow-buttons';
import Ruler from '../ruler/ruler';
import Labels from '../labels/labels';
import ResetRotation from '../reset-rotation/reset-ration';
import { centerMap } from '../../modules/run/run.controller';
import { GetBoundsBoxResponse } from 'app/utils/map.utils';
import { ControllersType } from '../../map';
import Weather from '../weather/weather';
import { WeatherOption } from '../../map.d';

/**
 *
 */
const renderControls = ({
  inspectionContext,
  controls,
  map,
  zoom,
  setZoom,
  settingsState,
  measureTool,
  weatherOverlay,
  setWeatherOverlay,
  isActiveLabels,
  isActiveRuler,
  setIsActiveLabels,
  setIsActiveRuler,
}: {
  inspectionContext?: InspectionProviderValue;
  settingsState: SettingsContext;
  controls: string[];
  map: google.maps.Map | undefined;
  zoom: number | undefined;
  setZoom: (data: number) => void;
  measureTool: MeasureTool | undefined;
  weatherOverlay: WeatherOption | undefined;
  setWeatherOverlay: (data: WeatherOption | undefined) => void;
  isActiveLabels: boolean;
  isActiveRuler: boolean;
  setIsActiveLabels: (data: boolean) => void;
  setIsActiveRuler: (data: boolean) => void;
}): ReactElement | null => {
  if (!map) return null;
  // TODO TYPES
  const renderers: { [key: string]: () => void } = {
    weather: function WeatherControl() {
      return (
        <Weather
          unit={settingsState.weatherUnit?.id || ''}
          selected={weatherOverlay}
          onSelect={setWeatherOverlay}
        />
      );
    },
    zoom: function ZoomControl() {
      return (
        <ZoomButton
          key="map-zoom-button"
          onZoomIn={() => {
            setZoom((zoom || 0) + 1);
            map.setZoom((zoom || 0) + 1);
          }}
          onZoomOut={() => {
            setZoom((zoom || 0) - 1);
            map.setZoom((zoom || 0) - 1);
          }}
        />
      );
    },
    follow: function FollowControl() {
      return (
        <FollowButtons
          key="map-follow-buttons"
          hidePigButton={!inspectionContext?.run?.launched}
          hideLoggedUserButton={!inspectionContext?.state.user_position}
          followingCurrentUserPosition={!!inspectionContext?.state.following_logged_user}
          followingPig={!!inspectionContext?.state.following_pig}
          onFollowingCurrentUserPositionToggle={() => {
            if (!inspectionContext?.state.following_logged_user) {
              map.setZoom(15);
            } else {
              centerMap({
                pipeline: inspectionContext.run?.pipeline,
                map,
                setMapCenter: (centerBox: GetBoundsBoxResponse) => map.fitBounds(centerBox, 70),
                mapCenter: undefined,
              });
            }

            if (inspectionContext) {
              inspectionContext.dispatch({ type: 'SET_IS_FOLLOWING_LOGGED_USER', data: !inspectionContext.state.following_logged_user })
              inspectionContext.dispatch({ type: 'SET_IS_FOLLOWING_PIG', data: false })
            }
          }}
          onFollowingPigToggle={() => {
            if (!inspectionContext?.state.following_pig) {
              map.setZoom(25);
            } else {
              centerMap({
                pipeline: inspectionContext.run?.pipeline,
                map,
                setMapCenter: (centerBox: GetBoundsBoxResponse) => map.fitBounds(centerBox, 70),
                mapCenter: undefined,
              });
            }

            if (inspectionContext) {
              inspectionContext.dispatch({ type: 'SET_IS_FOLLOWING_PIG', data: !inspectionContext.state.following_pig })
              inspectionContext.dispatch({ type: 'SET_IS_FOLLOWING_LOGGED_USER', data: false })
            }
          }
        }
        />
      );
    },
    measure: function MeasureToolControl() {
      return measureTool ? (
        <Ruler
          key="map-ruler"
          active={isActiveRuler}
          onClick={() => {
            if (measureTool && isActiveRuler) {
              measureTool.end();
              setIsActiveRuler(false);
              inspectionContext?.dispatch({ type: 'SET_HAS_RULER', data: false });
            }

            if (measureTool && !isActiveRuler) {
              measureTool.start([]);
              setIsActiveRuler(true);
              inspectionContext?.dispatch({ type: 'SET_HAS_RULER', data: true })
            }
          }}
        />
      ) : null;
    },
    labels: function LabelsControl() {
      return (
        <Labels
          key="map-labels"
          active={isActiveLabels}
          onClick={() => {
            if (inspectionContext?.dispatch) {
              inspectionContext.dispatch({ type: 'TOGGLE_HAS_LABELS' })
            }
            setIsActiveLabels(!isActiveLabels);
          }}
        />
      );
    },
  };

  return (
    <>
      {
        controls?.map((control: string) =>
          renderers[control] ? renderers[control]() : null
        )
      }
    </>
  )
};

interface Props {
  map: google.maps.Map | undefined;
  isStreetViewMode: boolean;
  zoom: number;
  setMeasureTool: (m: MeasureTool) => void;
  measureTool: MeasureTool | undefined;
  controllers: ControllersType[];
  view_mode: InspectionProviderValue['state']['view_mode'];
  isActiveLabels: boolean;
  isActiveRuler: boolean;
  setIsActiveLabels: (data: boolean) => void;
  setIsActiveRuler: (data: boolean) => void;
}

/**
 *
 * @returns
 */
const Controls = (props: Props) => {
  const settingsContext = useContext(SettingsStore);
  const inspectionContext = useContext(InspectionStore);

  // initialization states

  // map states
  const [zoom, setZoom] = useState<number>();
  const [tilt, setTilt] = useState<number>();
  const [heading, setHeading] = useState<number>();
  const [showControls, setShowControls] = useState<boolean>(true);
  const [weatherOverlay, setWeatherOverlay] = useState<WeatherOption>();

  useEffect(() => {
    if (props.controllers.includes('weather')) {
      changeMapOverlay({ map: props.map, weatherOverlay: weatherOverlay?.id });
    }
  }, [weatherOverlay]);

  // after map initialization
  useEffect(() => {
    if (props.map) {
      setListeners({
        setZoom,
        map: props.map,
        setTilt,
        setHeading,
      });

      renderMeasureTool({
        map: props.map,
        setMeasureTool: props.setMeasureTool,
        distanceUnit: settingsContext.state.distanceUnit,
      });
    }
  }, [props.map]);
  
  // update local zoom state
  useEffect(() => {
    setZoom(props.zoom);
  }, [props.zoom]);

  // update measure tool unit
  useEffect(() => {
    if (props.measureTool) {
      // measure tool update
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      if (settingsContext.state.distanceUnit?.id === 'ft' || settingsContext.state.distanceUnit?.id === 'mi') {
        props.measureTool.setOption('unit', MeasureTool.UnitTypeId.IMPERIAL);
      }

      if (settingsContext.state.distanceUnit?.id === 'km' || settingsContext.state.distanceUnit?.id === 'm') {
        props.measureTool.setOption('unit', MeasureTool.UnitTypeId.METRIC);
      }
    }
  }, [settingsContext.state.distanceUnit]);

  useEffect(() => {
    if (inspectionContext?.state && inspectionContext.state.has_labels) {
      props.setIsActiveLabels(true);
    }

    props.setIsActiveLabels(false);
  }, [inspectionContext?.state?.has_labels]);

  useEffect(() => {
    if (inspectionContext?.state && inspectionContext.state.has_ruler) {
      props.setIsActiveRuler(true);
    }

    props.setIsActiveRuler(false);
  }, [inspectionContext?.state?.has_ruler]);

  return (
    <ControlsComp $observerMode={props.view_mode === 'observer'}>
      <ToggleControlsContainer
        onClick={() => setShowControls(!showControls)}
      >
        {showControls ? <HideControls /> : <ShowControls />}
      </ToggleControlsContainer>

      {showControls ? renderControls({
        inspectionContext,
        controls: props.controllers,
        settingsState: settingsContext.state,
        map: props.map,
        weatherOverlay,
        setWeatherOverlay,
        zoom,
        setZoom,
        measureTool: props.measureTool,
        isActiveLabels: props.isActiveLabels,
        isActiveRuler: props.isActiveRuler,
        setIsActiveLabels: props.setIsActiveLabels,
        setIsActiveRuler: props.setIsActiveRuler,
      }) : null}

      {props.map && !props.isStreetViewMode && (tilt || heading) ? (
        <ResetRotation
          onClick={() => {
            setTilt(0);
            setHeading(0);

            props.map?.setTilt(0);
            props.map?.setHeading(0);
          }}
        />
      ) : null}
    </ControlsComp>
  );
};

Controls.defaultProps = {
};

export default Controls;
